export enum RpcMethod {
  oracle_chainId = 'oracle_chainId',
  oracle_getDerivedBlockByHash = 'oracle_getDerivedBlockByHash',
  oracle_getBundleReceipt = 'oracle_getBundleReceipt',
  oracle_getDerivedBlockByNumber = 'oracle_getDerivedBlockByNumber',
  oracle_getOperationalAddress = 'oracle_getOperationalAddress',
  oracle_call = 'oracle_call',
  oracle_call_old = 'oracle_call_old',
  oracle_getLogs = 'oracle_getLogs',
}

// recommendations requests, are routed to separate path
export enum RecommendationsRpcMethod {
  oracle_getOperationalAddress = 'oracle_getOperationalAddress',
  oracle_getTimePromise = 'oracle_getTimePromise',
  oracle_sendProofOfRecommendationOrigin = 'oracle_sendProofOfRecommendationOrigin',
  oracle_sendProofOfRecommendation = 'oracle_sendProofOfRecommendation',
  oracle_getProofOfRecommendations = 'oracle_getProofOfRecommendations',
  oracle_getProofOfRecommendationOrigin = 'oracle_getProofOfRecommendationOrigin',

  // developer support requests, can be disabled on live oracles
  dev_getProofOfRecommendations = 'dev_getProofOfRecommendations',
  dev_scanProofOfRecommendations = 'dev_scanProofOfRecommendations',
  dev_scanProofOfRecommendationOrigins = 'dev_scanProofOfRecommendationOrigins',
}

export enum WsMethod {
  oracle_subscribe = 'oracle_subscribe',
  oracle_unsubscribe = 'oracle_unsubscribe',

  oracle_chainId = 'oracle_chainId',
  oracle_getDerivedBlockByHash = 'oracle_getDerivedBlockByHash',
  oracle_getBundleReceipt = 'oracle_getBundleReceipt',
  oracle_getDerivedBlockByNumber = 'oracle_getDerivedBlockByNumber',
}

export enum SubscriptionType {
  newDerivedBlockHeaders = 'newDerivedBlockHeaders',

  // developer support events, can be disabled on live oracles
  devStepMetrics = 'devStepMetrics',
  devNewBlockBundle = 'devNewBlockBundle',
  devNewDerivedBlock = 'devNewDerivedBlock',
  devNewEvmBlock = 'devNewEvmBlock',
  devLog = 'devLog',
};

export type JsonRpcRequestId = number | string;

export interface JsonRpcRequest {
  jsonrpc: '2.0';
  method: string;
  params?: any[];
  id: JsonRpcRequestId;
}

export interface JsonRpcNotification {
  jsonrpc: '2.0';
  method: string;
  params: {
    result: any;
    subscription: string;
  };
}

export interface JsonRpcResponse {
  jsonrpc: '2.0';

  // Had a value for successful requests, might have a value for failed requests
  id: JsonRpcRequestId | null;
  
  // Result indicates success
  result?: any;

  // Error indicates failure
  error?: {
    code: number;
    // Limit this to single sentence
    message: string;
    data?: any;
  };
}