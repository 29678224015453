import 'regenerator-runtime/runtime';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import { JsonRpcRequest, SubscriptionType } from '../oracle/src/rpc/types';

// import captured from './data.json';

const items = new DataSet([]);
const container = document.getElementById('visualization');
const groups = new DataSet([
  { id: 'wsevm-0x4', content: 'ws rinkeby newBlock' },
  { id: 'wsevm-0x13881', content: 'ws mumbai newBlock' },
  { id: 'wsevm-0x61', content: 'ws tbsc newBlock' },
  { id: 'wsevm-0x7a69', content: 'ws hardhat newBlock' },
  { id: 'bb-0x4', content: `bundled rinkeby<br><strong>pacemaker</strong>` },
  { id: 'bb-0x13881', content: 'bundled mumbai' },
  { id: 'bb-0x61', content: 'bundled tbsc' },
  { id: 'bb-0x7a69', content: 'bundled hardhat' },
  { id: 'bb', content: 'block bundles' },
  { id: 'db', content: 'derived blocks (debug)' },
  // { id: 'vb', content: 'derived blocks (rpc)' },
]);
let stack = false;
const timeline = new Timeline(container, items, groups, {
  stack,
  height: '600px',
  verticalScroll: true,
});

// TODO PAR visualize derived blocks as well from official rpc api oracle_subscribe

// TODO PAR replace this with a decently structured react app which uses hooks and contexts for state and separate components for the views (still using parcel for builder) - worth doing call with Mariia for initial architecture. Endgoal: "have a mature codebase to extend the streamviewer efficeintly". Maybe add tailwindcss with default styles for easy styling and positioning. We have RPC API we can start rendering from "any" point in the derived chain.
//  TODO PAR startup streamviewer from an offset block (or from genesis)

let url = 'ws://localhost:3000';
if(window.location.host.indexOf('localhost') < 0 && window.location.host.indexOf('127.0.0.1') < 0 && window.location.host.indexOf('::1') < 0) {
  url = 'wss://'+window.location.host;
}

const subscriptions = {
  [SubscriptionType.newDerivedBlockHeaders]: SubscriptionType.newDerivedBlockHeaders,
  [SubscriptionType.devNewBlockBundle]: SubscriptionType.devNewBlockBundle,
  [SubscriptionType.devNewDerivedBlock]: SubscriptionType.devNewDerivedBlock,
  [SubscriptionType.devNewEvmBlock]: SubscriptionType.devNewEvmBlock,
  [SubscriptionType.devStepMetrics]: SubscriptionType.devStepMetrics,
  [SubscriptionType.devLog]: SubscriptionType.devLog,
}
const subscriptionIds = {};
async function connectStream() {
  const connection = new WebSocket(url);
  connection.onopen = () => {
    for(let key of Object.keys(subscriptions)) {
      connection.send(JSON.stringify({
        jsonrpc: "2.0",
        id: key,
        method: "oracle_subscribe",
        params: [key],
      } as JsonRpcRequest));
    }
  };
  connection.onerror = (err) => console.error(`error`, err);
  connection.onmessage = d => {
    toggleActivity()
    try {
      const res = JSON.parse(d.data);
      if(subscriptions[res.id]) {
        // Subscribe response
        subscriptionIds[res.result] = res.id;
      } else {
        // Normal message
        const { subscription, result } = res.params;
        render(subscriptionIds[subscription], result);
      }
    } catch(err) {
      console.error(err);
      alert('error see console CMD+SHIFT+I');
    }
  };
}

let counter = 0;
let options = ['|','/','-','\\'];
function toggleActivity() {
  document.getElementById('activity').innerText = options[(++counter) % options.length];
}

function render(type, data) {
  if(type === SubscriptionType.newDerivedBlockHeaders) {
    // TODO Fetch the block bundle and dump the receipts.
  }
  if(type === SubscriptionType.devNewBlockBundle) {
    renderBlockBundle(data); 
  }
  else if(type === SubscriptionType.devNewEvmBlock) {
    renderEvmBlock(data);
  }
  else if(type === SubscriptionType.devNewDerivedBlock) {
    renderDerivedBlock(data);
  }
  else if(type === SubscriptionType.devStepMetrics) {
    addStreamMetrics(data);
  } else if (type === SubscriptionType.devLog) {
    console.log(`syslog ${data.level} ${data.msg}`, ...data.props);
  }
  else {
    console.log('ignoring type: '+data.type);
  }
}

function renderEvmBlock(block) {
  const style = 'background-color: lightpink';
  const start = new Date(Number(block.timestamp)*1000);
  items.add([
    { id: 'wsevm-'+block.hash, start, content: block.hash.substring(2, 10), group: 'wsevm-'+block.chainId, style, type: 'point' }
  ]);
}

let prevHeader;
let colorCounterBlock = 0;
function renderDerivedBlock(block) {
  console.log({ block });
  const { header } = block;
  colorCounterBlock++;
  // First one we skip
  if(!prevHeader) {
    prevHeader = header;
    return
  }
  const style = ( colorCounterBlock % 2 ? 'background-color: coral' : 'background-color: blueviolet');
  const start = new Date(Number(prevHeader.closer.timestamp)*1000);
  const end = new Date(Number(header.closer.timestamp)*1000);
  items.add([
    { 
      id: 'db-'+header.hash, 
      start, 
      end, 
      content: `${header.hash.substring(2, 10)}
        <br>#: ${Number(header.number)}
        <br>bndl: ${header.bundleHash.substring(2, 10)}
        <br>clsr: ${header.closer.hash.substring(2, 10)}
        <br>strt: ${header.stateRoot.substring(2, 10)}
        <br>rcpthash: ${header.receiptHash.substring(2, 10)}
        <br>#logs: ${block.receipt.logs.length}`, 
      group: 'db', 
      style,
    }
  ]);
  prevHeader = header
}

// TODO PAR (take along in react refactor) (first blocks or first bundles are not rendered right now). Render unsure start blocks with less opacity and estimate timings based on content (now we skip them)

// BUNDLE RENDER
let focusExecuted = false;
let prevSecondaries = {};
let prevBundle;
let colorCounter = 0;
function renderBlockBundle(bundle) {
  if(prevBundle) {
    prevSecondaries[prevBundle.closer.chainId] = prevBundle.closer;
    for(let [cid, blocks] of Object.entries(prevBundle.blocks)) {
      prevSecondaries[cid] = blocks[blocks.length-1];
    }
  }
  colorCounter++;
  const style = ( colorCounter % 2 ? 'background-color: lightskyblue' : 'background-color: aquamarine');
  // First one we skip
  if(!prevBundle) {
    prevBundle = bundle;
    return
  }

  // Create node
  const from = Number(prevBundle.closer.timestamp)*1000;
  const to = Number(bundle.closer.timestamp)*1000;

  // Append bundle range to timeline
  let start = new Date(from);
  let end = new Date(to);
  items.add([
    { id: 'bb-'+bundle.hash, start, end, content: bundle.hash.substring(2, 10) + ' n:'+BigInt(bundle.closer.number), group: 'bb', style }
  ]);
  if(!focusExecuted) {
    timeline.setWindow(start, new Date(to + 120_000)); // 2min window initially
    focusExecuted = true;
  }

  // Append closer
  let prev = prevSecondaries[bundle.closer.chainId] || null;
  if(prev) {
    const closer = bundle.closer;
    start = new Date(Number(prev.timestamp)*1000);
    end = new Date(Number(closer.timestamp)*1000);
    items.add([{ 
      id: 'bb-cl-'+closer.hash, 
      start, 
      end, 
      content: closer.hash.substring(2, 10) + ' n:'+BigInt(closer.number) + ' txs:'+closer.evmBlock.transactions.length, 
      group: 'bb-'+closer.chainId, style 
    }]);
  }

  // Append bundle blocks to timeline
  for(let [cid, blocks] of Object.entries(bundle.blocks)) {
    prev = prevSecondaries[cid] || null;
    let start;
    for(let block of blocks) {
      if(prev) {
        start = new Date(Number(prev.timestamp)*1000);
      }
      end = new Date(Number(block.timestamp)*1000);
      prev = block;
      if(!start) continue;
      items.add([
        { 
          id: 'bb-b-'+block.hash, 
          start, 
          end, 
          content: block.hash.substring(2, 10) + ' n:'+BigInt(block.number) + ' txs:'+block.evmBlock.transactions.length, 
          group: 'bb-'+cid, 
          style,
        }
      ]);
    } 
  }

  // Advance forward (doesn't work with reverts)
  prevBundle = bundle;
}

const lastStreamMetrics = {};
let requested = 0;
function addStreamMetrics(d) {
  lastStreamMetrics[d.step] = d;
  if(requested == 0) {
    requested = window.requestAnimationFrame(renderStreamMetrics);
  }
}

function value(v) {
  if(v == null || v == undefined) {
    return '-';
  }
  return v;
}

function renderStreamMetrics() {
  requested = 0;
  const rows = Object.values(lastStreamMetrics).map(step => {
    return `<tr><td>${step.step}</td><td>${value(step.enqueues)}</td><td>${value(step.desiredSize)}</td><td>${value(step.queueTotalSize)}</td><td>${value(step.started)}</td><td>${value(step.pulling)}</td></tr>`;
  }).join("");
  document.getElementById('streamSteps').innerHTML = `<table>
  <tr>
  <th>step (not in stream order)</th><th>enqueues</th><th>desiredSize</th><th>queueTotalSize</th><th>started</th><th>pulling(readables)</th>
  </tr>
  ${rows}</table>`;
}

async function main() {
  console.log('starting');
  await connectStream();

  document.getElementById('toggleStack').addEventListener('click', e => {
    stack = !stack;
    timeline.setOptions({ stack });
  });
  document.getElementById('zoomTail').addEventListener('click', e => {
    if(!prevBundle) {
      return console.warn('btn works when a previous bundle was seen');
    }
    const from = Number(prevBundle.closer.timestamp) * 1000 - 30_000;
    const to = Number(prevBundle.closer.timestamp) * 1000 + 120_000 - 30_000;
    timeline.setWindow(from, to); // 5min window initially
  });
}

main().catch(err => {
  console.trace(err);
  throw err;
})